<template>
  <div>
    <!-- <s-e-o :title="title" /> -->
    <banner
      v-if="property"
      :link="`/property/${uprn}`"
      link-icon="chevron-left"
      link-text="Property"
      :title="formatStreetName(property.addressBase)"
      :text="formatAddressWithoutStreetName(property.addressBase)"
    />
    <div class="container py-5">
      <div v-if="lodgement" class="row justify-content-center">
        <div class="col-lg-10">
          <div class="pull">
            <measure-list :uprn="lodgement.propertyUPRN" :measures="lodgement.measures" />
          </div>
          <div class="row mb-5">
            <div class="col-lg-6">
              <template v-if="lodgement.tenure">
                <h3>Property tenure</h3>
                <dl>
                  <template v-if="lodgement.tenure.premisesTenure">
                    <dt>Tenure</dt>
                    <dd>{{ lodgement.tenure.premisesTenure }}</dd>
                  </template>
                  <template v-if="lodgement.tenure.residentName">
                    <dt>Resident name</dt>
                    <dd>{{ lodgement.tenure.residentName }}</dd>
                  </template>
                  <template v-if="lodgement.tenure.residentContactNumber">
                    <dt>Resident contact number</dt>
                    <dd>{{ lodgement.tenure.residentContactNumber }}</dd>
                  </template>
                  <template v-if="lodgement.tenure.residentAlternativeContactNumber">
                    <dt>Alternative contact number</dt>
                    <dd>{{ lodgement.tenure.residentAlternativeContactNumber }}</dd>
                  </template>
                  <template v-if="lodgement.tenure.ownerName">
                    <dt>Owner name</dt>
                    <dd>{{ lodgement.tenure.ownerName }}</dd>
                  </template>
                </dl>
              </template>
            </div>
          </div>
          <document-list
            :documents="lodgement.documents"
            :lodgement-id="lodgement.id"
            :certificate-number="lodgement.certificateNumber"
            :uprn="lodgement.propertyUPRN"
            :authenticated="authenticated"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';
// import SEO from './SEO.vue';
import MeasureList from './MeasureList.vue';
import Banner from './Banner.vue';
import DocumentList from './DocumentList.vue';
import { formatStreetName, formatAddressWithoutStreetName } from '../helpers/address';

export default {
  components: {
    // SEO,
    MeasureList,
    Banner,
    DocumentList,
  },
  data() {
    return {
      session: null,
      lodgement: null,
      lodgementId: this.$route.params.lodgementId,
      uprn: this.$route.params.uprn,
    };
  },
  computed: {
    authenticated() {
      return this.$store.state.authenticated;
    },
    property() {
      return this.$store.state.homeOwner.property;
    },
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
    if (!this.property) {
      this.$store
        .dispatch('homeOwner/getProperty', this.uprn)
        .then((resp) => {
          this.extractLodgement(resp);
        })
        .catch((err) => {
          if (err.response && err.response.status === 404) {
            this.$router.push('/property-not-found');
            return;
          }
          this.errorMessage = err;
        });
    } else {
      this.extractLodgement(this.property);
    }
  },
  methods: {
    formatStreetName,
    formatAddressWithoutStreetName,
    extractLodgement(property) {
      const lodgements = property.lodgements.filter((x) => x.id === this.lodgementId);
      const firstLodgement = lodgements[0];
      this.lodgement = firstLodgement;
    },
  },
};
</script>
