<template>
  <div>
    <h2>Measures ({{ measures.length }})</h2>
    <ul class="list-group list-group-flush">
      <li v-for="measure in measures" :key="measure.id" class="list-group-item">
        <h3 class="mb-0">
          <router-link :to="`/property/${uprn}/${measure.lodgementId}/${measure.id}`">
            {{ measure.measureType }}
          </router-link>
        </h3>
        <p>Installed {{ $helpers.dateString(measure.installedDate) }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  filters: {
    formatDate(date) {
      return moment(date).format('Do MMMM YYYY');
    },
  },
  props: {
    measures: {
      type: Array,
      required: false,
      default: () => [],
    },
    uprn: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
